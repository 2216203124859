/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-misused-promises */
import "./Workspace.css"

import { useForm } from "antd/es/form/Form"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import Arrow from "../../assets/Arrow.svg"
import { type IStep } from "../../types/IStep"

type OwnProps = {
  initialStep: IStep<unknown>
}

const Workspace = (props: OwnProps): React.ReactElement => {
  const [currentStep, setCurrentStep] = useState<IStep>(props.initialStep)
  const navigate = useNavigate()
  const [form] = useForm()

  const runNext = async function () {
    if (currentStep.onNext) currentStep.onNext(form.getFieldsValue())

    if (currentStep.redirect) navigate(currentStep.redirect)
    else if (currentStep.next) setCurrentStep(currentStep.next)
  }

  const handleNext = async function () {
    try {
      await form.validateFields()

      if (currentStep.nextRecaptcha) {
        const container = document.getElementById("captcha-container")
        const test = document.getElementById("captcha-test")

        if (container && test) {
          container.style.visibility = "visible"
          // @ts-expect-error
          AwsWafCaptcha.renderCaptcha(test, {
            apiKey: process.env.REACT_APP_RECAPTCHA_KEY,
            onSuccess: async () => {
              await runNext()
            },
            onError: (err: unknown) => {
              console.warn(err)
              navigate("/error")
            }
          })
        }
      } else {
        console.log(currentStep)
        await runNext()
      }
    } catch (invalid) {
      console.warn(invalid)
    }
  }

  const handlePrevious = function () {
    if (currentStep.redirectPrevious) navigate(currentStep.redirectPrevious)
    else if (currentStep.previous) setCurrentStep(currentStep.previous)
  }

  return (<>
    <div className="workspace-container">{currentStep.current({ formRef: form })}</div>
    <div id="captcha-container"><div id="captcha-test"></div></div>
    {
      (<div className="workspace-footer">
        {
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          (currentStep.previous || currentStep.redirectPrevious) && (
            <div onClick={handlePrevious} className="workspace-footer-button workspace-footer-return-button">
                <img src={Arrow} alt="return-arrow" className="workspace-footer-return-arrow" />
                <span>{currentStep.previousText ?? "Voltar"}</span>
            </div>)
        }
        {
          (currentStep.next || currentStep.redirect) && (
            <div onClick={handleNext} className="workspace-footer-button workspace-footer-next-button">
              <span>{currentStep.nextText ?? "Próximo"}</span>
              <img src={Arrow} alt="next-arrow" className="workspace-footer-next-arrow" />
            </div>)
        }
      </div>)
    }
  </>)
}

export default Workspace
