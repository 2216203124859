import { ConfigProvider } from "antd"
import React from "react"
import { Provider } from "react-redux"
// import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"

import router from "./router"
import store from "./store/store"

const App = function (): JSX.Element {
  return (
    <div className="App">
      <ConfigProvider>
        <Provider store={store}>
          <RouterProvider router = { router() } />
        </Provider>
      </ConfigProvider>
    </div>
  )
}

export default App
