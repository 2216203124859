import { call, put, select } from "redux-saga/effects"

import ChecklistSinistroAPI, { type SendFormArgs } from "../../../APIs/ChecklistSinistroAPI"
import { sendFormError, sendFormSuccess } from "."
import { type Form } from "./types"

type Store = { form: { data: Form } }
export function * sendFormSaga (): Generator {
  try {
    const store = ((yield select()) as Store).form.data
    const data: SendFormArgs = {
      accidentDetails: store.accidentDetails,
      documents: store.documents,
      location: store.location,
      vehicle: store.vehicle,
      witness: store.witness,
      recaptchaToken: ""
    }

    yield call(ChecklistSinistroAPI.sendForm, data)

    yield put(sendFormSuccess())
  } catch (err) {
    console.log({ err })
    yield put(sendFormError())
  }
}
