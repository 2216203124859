import "./FormView.css"

import { CarOutlined } from "@ant-design/icons"
import React, { type PropsWithChildren } from "react"

import { CarCrashFilled, DocumentsOutlined, GPSFilled, UserFilled } from "../../components/icons"

type OwnProps = {
  title: string
  step?: number
}

const FormView = (props: PropsWithChildren<OwnProps>): React.ReactElement => {
  return (<div className="formview-container">
    <div className="formview-title">{props.title}</div>
    <div className="formview-steps">
      <div className="step-selector-container">
        <div className={`step-selector-button${props.step && props.step >= 1 ? "-active" : ""}`}><DocumentsOutlined className="step-selector-icon" /></div>
        <div className={`step-selector-button${props.step && props.step >= 2 ? "-active" : ""}`}><CarOutlined className="step-selector-icon" /></div>
        <div className={`step-selector-button${props.step && props.step >= 3 ? "-active" : ""}`}><CarCrashFilled className="step-selector-icon"/></div>
        <div className={`step-selector-button${props.step && props.step >= 4 ? "-active" : ""}`}><GPSFilled className="step-selector-icon" /></div>
        <div className={`step-selector-button${props.step && props.step >= 5 ? "-active" : ""}`}><UserFilled className="step-selector-icon" /></div>
      </div>
    </div>
    <div className="formview-content">{props.children}</div>
  </div>)
}

export default FormView
