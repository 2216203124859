import React from "react"

import store from "../store/store"
import FormView from "../views/FormView"
import Review from "../views/Review"
import Step from "./Step"

class ReviewStep extends Step {
  constructor () {
    super({
      current: () => {
        return (<FormView title="Revisão" step={5}>
          <Review data={store.getState().form.data}/>
        </FormView>)
      }
    })
  }
}

export default ReviewStep
