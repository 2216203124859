import { FormFactory } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type FormInstance } from "antd"
import React from "react"

import { setAccidentDetailsDetails } from "../store/ducks/form"
import { type AccidentDetailsForm } from "../store/ducks/form/types"
import store from "../store/store"
import FormView from "../views/FormView"
import Step from "./Step"

class AccidentDetailsStep extends Step<Pick<AccidentDetailsForm, "details">> {
  constructor () {
    super({
      current: (props: { formRef: FormInstance }) => {
        return (<FormView title="Detalhes do Acidente" step={3}>
          <FormFactory
            formRef={props.formRef}
            formConfig={{
              labelAlign: "left",
              // labelWrap: true,
              // labelCol: { span: 6 },
              wrapperCol: { span: 23 },
              layout: "vertical"
            }}
            fields={[
              { label: "Descreva detalhadamente como ocorreu o acidente", name: "details", type: "textarea", min: 50, max: 1500, required: true }
            ]}
          />
        </FormView>)
      },
      onNext: (formData) => {
        store.dispatch(setAccidentDetailsDetails(formData))
      }
    })
  }
}

export default AccidentDetailsStep
