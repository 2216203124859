import AccidentDetailsStep from "./AccidentDetailsStep"
import AccidentSpotsStep from "./AccidentSpotsStep"
import AccidentStep from "./AccidentStep"
import DocumentsStep from "./DocumentsStep"
import LocationStep from "./LocationStep"
import ReviewStep from "./ReviewStep"
import TipsStep from "./TipsStep"
import VehicleStep from "./VehicleStep"
import WelcomeStep from "./Welcome"
import WitnessStep from "./WitnessStep"

const welcomeStep = new WelcomeStep()
const tipsStep = new TipsStep()

const documentsStep = new DocumentsStep()
const vehicleStep = new VehicleStep()
const accidentStep = new AccidentStep()
const accidentDetailsStep = new AccidentDetailsStep()
const accidentSpotsStep = new AccidentSpotsStep()
const locationStep = new LocationStep()
const reviewStep = new ReviewStep()
const witnessStep = new WitnessStep()

welcomeStep.next = tipsStep
welcomeStep.nextText = "Vamos Começar!"

// tipsStep.redirect = "/form"
tipsStep.next = documentsStep
tipsStep.nextText = "Aceitar termos e condições"
tipsStep.previous = welcomeStep

documentsStep.next = vehicleStep
documentsStep.nextText = "Dados do Veículo"

vehicleStep.previous = documentsStep
vehicleStep.next = accidentStep
vehicleStep.nextText = "Detalhes do Acidente"

accidentStep.previous = vehicleStep
accidentStep.next = accidentSpotsStep

accidentSpotsStep.next = accidentDetailsStep
accidentSpotsStep.previous = accidentStep

accidentDetailsStep.previous = accidentSpotsStep
accidentDetailsStep.next = locationStep
accidentDetailsStep.nextText = "Local de Atendimento"

locationStep.previous = accidentDetailsStep
locationStep.next = reviewStep
locationStep.nextText = "Revisão"

reviewStep.previous = locationStep
reviewStep.previousText = "Alterar Informações"
reviewStep.next = witnessStep
reviewStep.nextText = "Dados do Declarante"

witnessStep.previous = reviewStep
witnessStep.redirect = "/send"
witnessStep.nextText = "Enviar"
witnessStep.nextRecaptcha = true

export default {
  documentsStep,
  vehicleStep,
  welcomeStep,
  tipsStep,
  accidentDetailsStep,
  accidentSpotsStep,
  reviewStep,
  locationStep,
  witnessStep
}
