import Icon, { type CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"
import React from "react"

const UserFilledSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 82 105" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_952_5357)">
      <path d="M41.1463 0.160156C54.9936 0.160156 66.2152 11.5247 66.2152 25.5502C66.2152 39.5906 54.9936 50.953 41.1463 50.953C27.3118 50.953 16.0859 39.5906 16.0859 25.5502C16.0859 11.5247 27.3118 0.160156 41.1463 0.160156Z" fill="currentColor" />
      <path d="M81.3674 104.961H81.3084H0.914062V91.1447C0.914062 73.1128 12.1146 57.823 27.7332 52.1236L41.147 70.2409L54.5565 52.1236C70.1794 57.823 81.3674 73.1128 81.3674 91.1447V104.961Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_952_5357">
        <rect width="80.4533" height="104.801" fill="currentColor" transform="translate(0.914062 0.160156)" />
      </clipPath>
    </defs>
  </svg>
)

const CarCrashSvg = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127 126" width="1.5em" height="1.5em" fill="currentColor">
    <path d="m54.2 24.8l-11.6 9-9.6-11.8-2 15-15-2 9 12-12 9 15 3-2 14 7-4c0 0 1.4-14.6 4-18 2.6-3.4 9-5 9-5l8-11z" />
    <path fillRule="evenodd"
      d="m38 63c0 0 2.2-14.3 11-13 3.7-5.9 7-16.8 21-17 7.8-0.1 22.6 4.7 26.6 6.6 3.9 1.8 7.4 7.4 7.4 7.4 0 0 3.9 9.2 3.1 19 2.5 2.1 4.8 4.8 4.9 9-1.3 6.7-3.8 13.2-6.8 14.1 0.3 3.5-2.4 11.8-6.2 11.9-3.8 0.1-8.9-2.5-10-4-1.1-1.5-0.1-6.1 1-6-0.1-1.6 0-3 0-3l-38-10c0 0 0.2 4.2-1 6-1.2 1.8-3.3 2.9-5 3-1.7 0.1-9.4-1.6-10-6-0.5-4.4 3-9 3-9 0 0-4.5-5.5-1-9zm58.6-0.5v-7.6c0 0 0-3.7-3.6-5.9-3.6-2.2-23.9-6.6-26-6-2.1 0.6-7.4 8.9-7.4 8.9zm-50.5-2.5c0 0-0.7 2-0.4 3.3 0.4 1.3 1.4 3.6 3.7 4.6 2.3 1 6.2 1.7 7.6 1.1 1.5-0.6 1.3-2.5 1-5-0.2-2.4-4-6-4-6 0 0-2.7-0.5-5 0-2.2 0.6-2.9 2-2.9 2zm45.5 14.5c0 0-1.7 3.2 0.4 4.5 2.2 1.3 5.7 1.8 8 2 2.3 0.2 3.7-2.8 3.7-2.8l0.3-4.2c0 0-0.5-2.7-3-3-2.4-0.3-3.4-0.6-5 0-1.6 0.7-4.4 3.5-4.4 3.5z" />
  </svg>
)

const DocumentsSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 83 83" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_955_5218)">
      <path d="M59.417 0.757812C59.1009 0.757812 28.816 0.757812 28.816 0.757812C23.42 0.757812 18.6157 5.70756 18.6157 10.9582L15.6678 11.027C10.2743 11.027 5.86523 15.9078 5.86523 21.1585V72.1602C5.86523 77.4109 10.6696 82.3605 16.0656 82.3605H54.3169C59.7128 82.3605 64.5172 77.4109 64.5172 72.1602H67.0672C72.4633 72.1602 77.2676 67.2106 77.2676 61.9599V21.2197L59.417 0.757812ZM54.3169 77.2605H16.0656C13.388 77.2605 10.9655 74.7589 10.9655 72.1602V21.1585C10.9655 18.56 13.1458 16.1425 15.8234 16.1425L18.6157 16.0583V61.9599C18.6157 67.2106 23.42 72.1602 28.816 72.1602H59.417C59.417 74.7588 56.9944 77.2605 54.3169 77.2605ZM72.1675 61.9599C72.1675 64.5583 69.7448 67.06 67.0672 67.06H28.816C26.1384 67.06 23.7158 64.5584 23.7158 61.9599V10.9582C23.7158 8.35972 26.1384 5.85807 28.816 5.85807H54.3169C54.2759 11.7309 54.3169 16.1222 54.3169 16.1222C54.3169 21.4212 59.0855 26.2587 64.5172 26.2587C64.5172 26.2587 67.2254 26.2587 72.1675 26.2587V61.9599ZM64.5172 21.1585C61.8014 21.1585 59.417 16.2241 59.417 13.5771C59.417 13.5771 59.417 10.8944 59.417 5.93705V5.93192L72.1675 21.1585H64.5172ZM59.417 36.505H36.4663C35.0587 36.505 33.9162 37.6449 33.9162 39.0525C33.9162 40.4601 35.0587 41.6 36.4663 41.6H59.417C60.8247 41.6 61.9671 40.46 61.9671 39.0525C61.9671 37.6449 60.8247 36.505 59.417 36.505ZM59.417 49.2426H36.4663C35.0587 49.2426 33.9162 50.3825 33.9162 51.79C33.9162 53.1977 35.0587 54.3376 36.4663 54.3376H59.417C60.8247 54.3376 61.9671 53.1977 61.9671 51.79C61.9671 50.3825 60.8247 49.2426 59.417 49.2426Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_955_5218">
        <rect width="81.6027" height="81.6027" fill="currentColor" transform="translate(0.765625 0.757812)" />
      </clipPath>
    </defs>
  </svg>
)

const ArrowSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.9166 6.24996V38.7208L11.8895 27.6937C11.0759 26.8802 9.75719 26.8802 8.94365 27.6937C8.13011 28.5072 8.13011 29.8265 8.94365 30.6401L23.527 45.2234C24.3405 46.0369 25.6598 46.0369 26.4728 45.2234L41.0562 30.6401C41.4629 30.2333 41.6666 29.7 41.6666 29.1666C41.6666 28.6338 41.4629 28.1005 41.0562 27.6937C40.2426 26.8802 38.9233 26.8802 38.1103 27.6937L27.0832 38.7208V6.24996C27.0832 5.09996 26.1504 4.16663 24.9999 4.16663C23.8494 4.16663 22.9166 5.09944 22.9166 6.24996Z" fill="currentColor" />
  </svg>
)

const CanvaSvg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 27H6C5.20435 27 4.44129 26.6839 3.87868 26.1213C3.31607 25.5587 3 24.7956 3 24V8C3 7.20435 3.31607 6.44129 3.87868 5.87868C4.44129 5.31607 5.20435 5 6 5H26C26.7956 5 27.5587 5.31607 28.1213 5.87868C28.6839 6.44129 29 7.20435 29 8V24C29 24.7956 28.6839 25.5587 28.1213 26.1213C27.5587 26.6839 26.7956 27 26 27ZM6 7C5.73478 7 5.48043 7.10536 5.29289 7.29289C5.10536 7.48043 5 7.73478 5 8V24C5 24.2652 5.10536 24.5196 5.29289 24.7071C5.48043 24.8946 5.73478 25 6 25H26C26.2652 25 26.5196 24.8946 26.7071 24.7071C26.8946 24.5196 27 24.2652 27 24V8C27 7.73478 26.8946 7.48043 26.7071 7.29289C26.5196 7.10536 26.2652 7 26 7H6Z" fill="black" />
    <path d="M21 15C20.4067 15 19.8266 14.8241 19.3333 14.4944C18.8399 14.1648 18.4554 13.6962 18.2284 13.1481C18.0013 12.5999 17.9419 11.9967 18.0576 11.4147C18.1734 10.8328 18.4591 10.2982 18.8787 9.87868C19.2982 9.45912 19.8328 9.1734 20.4147 9.05765C20.9967 8.94189 21.5999 9.0013 22.1481 9.22836C22.6962 9.45543 23.1648 9.83994 23.4944 10.3333C23.8241 10.8266 24 11.4067 24 12C24 12.7957 23.6839 13.5587 23.1213 14.1213C22.5587 14.6839 21.7957 15 21 15ZM21 11C20.8022 11 20.6089 11.0587 20.4444 11.1685C20.28 11.2784 20.1518 11.4346 20.0761 11.6173C20.0004 11.8 19.9806 12.0011 20.0192 12.1951C20.0578 12.3891 20.153 12.5673 20.2929 12.7071C20.4327 12.847 20.6109 12.9422 20.8049 12.9808C20.9989 13.0194 21.2 12.9996 21.3827 12.9239C21.5654 12.8482 21.7216 12.72 21.8315 12.5556C21.9414 12.3911 22 12.1978 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z" fill="black" />
    <path d="M25.9996 27.0001C25.8349 26.9992 25.6729 26.9577 25.5281 26.8792C25.3833 26.8007 25.2601 26.6876 25.1696 26.5501L20.8296 20.0501C20.7381 19.914 20.6145 19.8024 20.4698 19.7253C20.325 19.6482 20.1636 19.6078 19.9996 19.6078C19.8356 19.6078 19.6741 19.6482 19.5293 19.7253C19.3846 19.8024 19.261 19.914 19.1696 20.0501L18.8296 20.5501C18.6733 20.7441 18.4501 20.8728 18.2039 20.911C17.9577 20.9491 17.7061 20.894 17.4984 20.7564C17.2907 20.6187 17.1419 20.4085 17.081 20.1669C17.0202 19.9253 17.0518 19.6696 17.1696 19.4501L17.4996 18.9401C17.7732 18.5275 18.1447 18.1891 18.5809 17.955C19.0171 17.7209 19.5045 17.5984 19.9996 17.5984C20.4946 17.5984 20.982 17.7209 21.4182 17.955C21.8544 18.1891 22.2259 18.5275 22.4996 18.9401L26.8296 25.4501C26.9743 25.6705 27.0261 25.9393 26.9736 26.1977C26.9212 26.4562 26.7688 26.6835 26.5496 26.8301C26.3885 26.9429 26.1962 27.0023 25.9996 27.0001Z" fill="black" />
    <path d="M6.00002 27C5.80841 26.9995 5.62098 26.9439 5.46002 26.84C5.23754 26.6965 5.08102 26.4707 5.02478 26.212C4.96854 25.9533 5.01718 25.6829 5.16002 25.46L11.39 15.84C11.6604 15.4222 12.0305 15.0782 12.4669 14.839C12.9033 14.5999 13.3924 14.4731 13.89 14.47C14.3849 14.4698 14.8722 14.592 15.3084 14.8258C15.7446 15.0596 16.1162 15.3977 16.39 15.81L22.81 25.45C22.9278 25.6695 22.9594 25.9252 22.8985 26.1668C22.8377 26.4084 22.6889 26.6186 22.4812 26.7563C22.2735 26.8939 22.0219 26.949 21.7757 26.9109C21.5294 26.8727 21.3063 26.744 21.15 26.55L14.72 16.92C14.6294 16.7824 14.5063 16.6694 14.3615 16.5909C14.2167 16.5123 14.0547 16.4708 13.89 16.47C13.7244 16.4719 13.5619 16.515 13.417 16.5952C13.2721 16.6755 13.1495 16.7906 13.06 16.93L6.84002 26.54C6.74967 26.6808 6.62543 26.7967 6.47867 26.8771C6.33192 26.9574 6.16734 26.9997 6.00002 27Z" fill="black" />
  </svg>
)

const GPSSvg = () => (
  <svg width="2em" height="2em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1438_1576)">
      <path d="M20.0003 19.9999C21.8413 19.9999 23.3337 18.5075 23.3337 16.6666C23.3337 14.8256 21.8413 13.3333 20.0003 13.3333C18.1594 13.3333 16.667 14.8256 16.667 16.6666C16.667 18.5075 18.1594 19.9999 20.0003 19.9999Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.3338 16.6666C28.3585 18.069 28.0013 19.4516 27.3005 20.6666C25.6005 23.4999 20.0005 31.6666 20.0005 31.6666C20.0005 31.6666 14.4005 23.4999 12.6838 20.6833C11.9859 19.4618 11.6344 18.073 11.6671 16.6666C11.6671 14.4564 12.5451 12.3368 14.1079 10.774C15.6707 9.21123 17.7903 8.33325 20.0005 8.33325C22.2106 8.33325 24.3302 9.21123 25.893 10.774C27.4558 12.3368 28.3338 14.4564 28.3338 16.6666Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1438_1576">
        <rect width="40" height="40" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>

)

export const GPSFilled = (props: Partial<CustomIconComponentProps>) => (<Icon component={GPSSvg} {...props} />)
export const UserFilled = (props: Partial<CustomIconComponentProps>) => (<Icon component={UserFilledSvg} {...props} />)
export const CarCrashFilled = (props: Partial<CustomIconComponentProps>) => (<Icon component={CarCrashSvg} {...props} />)
export const DocumentsOutlined = (props: Partial<CustomIconComponentProps>) => (<Icon component={DocumentsSvg} {...props} />)
export const ArrowOutlined = (props: Partial<CustomIconComponentProps>) => (<Icon component={ArrowSvg} {...props} />)
export const CanvaOutlined = (props: Partial<CustomIconComponentProps>) => (<Icon component={CanvaSvg} {...props} />)
