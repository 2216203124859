import { FormFactory, masks } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type FormInstance } from "antd"
import React from "react"

import { UFBrasil } from "../helpers/format"
import { setLocation } from "../store/ducks/form"
import { type LocationForm } from "../store/ducks/form/types"
import store from "../store/store"
import FormView from "../views/FormView"
import Step from "./Step"

class LocationStep extends Step<LocationForm> {
  constructor () {
    super({
      current: (props: { formRef: FormInstance }) => {
        return (<FormView title="Local de atendimento" step={4}>
          <FormFactory
            formRef={props.formRef}
            formConfig={{
              labelAlign: "left",
              labelWrap: true,
              labelCol: { span: 6 },
              wrapperCol: { span: 16 },
              layout: "horizontal"
            }}
            fields={[
              {
                label: "CEP do local em que deseja o atendimento",
                name: "cep",
                type: "input",
                required: true,
                mask: masks.cep
              },
              { label: "Cidade", name: "city", type: "input", required: true },
              { label: "Estado", name: "state", type: "select", options: UFBrasil, required: true }
            ]}
          />
        </FormView>)
      },
      onNext: (formData) => {
        store.dispatch(setLocation(formData))
      }
    })
  }
}

export default LocationStep
