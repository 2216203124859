import React from "react"

import Tips from "../views/Tips"
import Step from "./Step"

class TipsStep extends Step {
  constructor () {
    super({
      current: () => <Tips />
    })
  }
}

export default TipsStep
