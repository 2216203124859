import { FormFactory } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type FormInstance } from "antd"
import React from "react"

import { setAccidentDetailsSpots } from "../store/ducks/form"
import { type AccidentDetailsForm } from "../store/ducks/form/types"
import store from "../store/store"
import FormView from "../views/FormView"
import Step from "./Step"

class AccidentSpotsStep extends Step<Pick<AccidentDetailsForm, "damagedSpots">> {
  constructor () {
    super({
      current: (props: { formRef: FormInstance }) => {
        return (<FormView title="Detalhes do Acidente" step={3}>
          <FormFactory
            formRef={props.formRef}
            formConfig={{
              labelAlign: "left",
              layout: "vertical"
            }}
            fields={[
              {
                label: "Selecione os vidros danificados",
                name: "damagedSpots",
                type: "selectbadges",
                items: [
                  { label: "Para-brisa", value: "central-parabrisa", sublabel: "(Frontal)", group: "Centrais" },
                  { label: "Vidro Traseiro", value: "central-traseiro", sublabel: "(Vigia)", group: "Centrais" },
                  { label: "Esquerdo", value: "dianteiro-esquerdo", sublabel: "(Motorista)", group: "Lateral Dianteiro" },
                  { label: "Direito", value: "dianteiro-direito", sublabel: "(Passageiro)", group: "Lateral Dianteiro" },
                  { label: "Esquerdo", value: "traseiro-esquerdo", sublabel: "(Motorista)", group: "Lateral Traseiro" },
                  { label: "Direito", value: "traseiro-direito", sublabel: "(Passageiro)", group: "Lateral Traseiro" },
                  { label: "Outros", value: "outros", group: "Outros" }
                ],
                groups: ["Centrais", "Lateral Dianteiro", "Lateral Traseiro", "Outros"],
                required: true
              },
              {
                label: "Outros",
                name: "otherDamages",
                type: "input",
                required: true,
                max: 20,
                condition: {
                  comparison: "outros",
                  field: "damagedSpots",
                  operation: "contains"
                }
              }
            ]}
          />
        </FormView>)
      },
      onNext: (formData) => {
        store.dispatch(setAccidentDetailsSpots(formData))
      }
    })
  }
}

export default AccidentSpotsStep
