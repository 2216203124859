import "./Tips.css"

import { CheckCircleOutlined, SendOutlined, SolutionOutlined } from "@ant-design/icons"
import { Popover } from "antd"
import React, { useEffect, useState } from "react"

import { ArrowOutlined, CarCrashFilled, DocumentsOutlined, UserFilled } from "../../components/icons"

const Tips = (): React.ReactElement => {
  const [hovered, setHovered] = useState(false)
  const [popTimeout, setPopTimeout] = useState<NodeJS.Timeout>()
  const [documents, setDocuments] = useState(false)

  const HoverContent = <div>Clique para ver!</div>

  useEffect(() => {
    setTimeout(() => {
      setHovered(true)
    }, 500)

    setPopTimeout(setTimeout(() => {
      setHovered(false)
    }, 10000))
  }, [])

  const handleHoverChange = (open: boolean) => {
    setHovered(open)
    clearTimeout(popTimeout)
  }

  return (<div className="view-tips">
    <span className="tips-description">Algumas dicas e informações</span>
    <div className="tips-tips">
      <div className="tips-form">
        <div className="tips-form-filling"><CheckCircleOutlined className="tips-icon" /><span>Após preencher todos os campos, revise cuidadosamente as informações antes de prosseguir.</span></div>
        <div className="tips-form-finish"><SendOutlined className="tips-icon" /><span>Para finalizar, clique no botão “Enviar” para concluir o formulário. Nossa equipe entrará em contato em até 48h úteis para atualizá-lo sobre o andamento do processo.</span></div>
        <div className="tips-form-documents"><SolutionOutlined className="tips-icon" /><span><b>Certifique-se de ter em mãos todos os <Popover className="tips-documents-link" content={HoverContent} trigger="hover" open={hovered} onOpenChange={handleHoverChange}><span onClick={() => { setDocuments(true) }}>documentos e informações relevantes</span></Popover> sobre o incidente.</b></span></div>
      </div>
      <div className="tips-help"><b>Precisa de ajuda?</b><br/>
Não hesite em nos contatar pelo e-mail suporte@kintomobility.com.br ou pelo telefone 0800 703 02 06 para assistência durante o preenchimento.</div>
    </div>

    <div className={`tips-documents-page ${documents ? "tips-documents-page-open" : ""}`} id="tips-documents-page">
    <div className="tips-documents-page-content">
      <span><b>Campos e documentos para abertura do formulário</b></span>
      <div className="tips-documents-page-sections">
          <UserFilled className="tips-documents-page-icon-info"/>
          <div className="tips-documents-page-section-info">
            <span>Informações do Proprietário do Veículo</span>
            <ul>
              <li>Nome completo</li>
              <li>Número de telefone para contato</li>
            </ul>
          </div>

          <CarCrashFilled className="tips-documents-page-icon-crash"/>
          <div className="tips-documents-page-section-crash">
            <span>Detalhes do Sinistro</span>
            <ul>
              <li>Data do evento</li>
              <li>Hora aproximada</li>
              <li>Localização do incidente</li>
            </ul>
            <br/>
            <span>Descrição do acidente</span>
            <ul>
              <li>Detalhes do ocorrido (Seja o mais específico possível)</li>
            </ul>
          </div>

          <DocumentsOutlined className="tips-documents-page-icon-attachments"/>
          <div className="tips-documents-page-section-attachments">
            <span>Documentação Anexa</span>
            <ul>
              <li>Cópia da CNH do Condutor</li>
              <li>Fotos do Vidro Danificado</li>
            </ul>
          </div>
      </div>
      <div onClick={() => { setDocuments(false) }} className="tips-documents-page-return-button"><span><ArrowOutlined className="tips-documents-page-return-arrow"/>&nbsp;Voltar</span></div>
    </div>
    </div>
  </div>)
}

export default Tips
