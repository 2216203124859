const trullyValues: unknown[] = [true, "true", 1]
const falselyValues: unknown[] = [false, "false", 0, undefined, null]

export function toReadableBoolean (value?: boolean): string {
  if (trullyValues.includes(value)) return "Sim"
  else if (falselyValues.includes(value)) return "Não"
  else return "Não"
}

export function toReadableSpot (value?: string): string {
  switch (value) {
    case "central-parabrisa": return "Para-Brisas"
    case "central-traseiro": return "Vidro Traseiro"
    case "dianteiro-esquerdo": return "Esquerdo"
    case "dianteiro-direito": return "Direito"
    case "traseiro-esquerdo": return "Esquerdo"
    case "traseiro-direito": return "Direito"
    default: return ""
  }
}

export const UFBrasil = [{ label: "Acre", value: "AC" },
  { label: "Alagoas", value: "AL" },
  { label: "Amapá", value: "AP" },
  { label: "Amazonas", value: "AM" },
  { label: "Bahia", value: "BA" },
  { label: "Ceará", value: "CE" },
  { label: "Distrito Federal", value: "DF" },
  { label: "Espírito Santo", value: "ES" },
  { label: "Goiás", value: "GO" },
  { label: "Maranhão", value: "MA" },
  { label: "Mato Grosso", value: "MT" },
  { label: "Mato Grosso do Sul", value: "MS" },
  { label: "Minas Gerais", value: "MG" },
  { label: "Pará", value: "PA" },
  { label: "Paraíba", value: "PB" },
  { label: "Paraná", value: "PR" },
  { label: "Pernambuco", value: "PE" },
  { label: "Piauí", value: "PI" },
  { label: "Rio de Janeiro", value: "RJ" },
  { label: "Rio Grande do Norte", value: "RN" },
  { label: "Rio Grande do Sul", value: "RS" },
  { label: "Rondônia", value: "RO" },
  { label: "Roraima", value: "RR" },
  { label: "Santa Catarina", value: "SC" },
  { label: "São Paulo", value: "SP" },
  { label: "Sergipe", value: "SE" },
  { label: "Tocantins", value: "TO" }]

export async function StreamToJson (body: ReadableStream) {
  const reader = body.getReader() // `ReadableStreamDefaultReader`
  const decoder = new TextDecoder()
  const chunks: string[] = []

  async function read () {
    const { done, value } = await reader.read()

    // all chunks have been read?
    if (done) {
      return JSON.parse(chunks.join(""))
    }

    const chunk = decoder.decode(value, { stream: true })
    chunks.push(chunk)
    return read() // read the next chunk
  }

  return read()
}
