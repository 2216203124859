import { FormFactory, masks, validators } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type FormInstance } from "antd"
import React from "react"

import { setVehicle } from "../store/ducks/form"
import { type VehicleForm } from "../store/ducks/form/types"
import store from "../store/store"
import FormView from "../views/FormView"
import Step from "./Step"

class VehicleStep extends Step<VehicleForm> {
  constructor () {
    super({
      current: (props: { formRef: FormInstance }) => {
        return (<FormView title="Dados do Veículo" step={2}>
          <FormFactory
            formRef={props.formRef}
            formConfig={{
              labelAlign: "left",
              labelWrap: true,
              labelCol: { span: 6 },
              wrapperCol: { span: 16 },
              layout: "horizontal"
            }}
            fields={[
              { label: "Protocolo de atendimento", name: "protocol", type: "input", required: false },
              {
                label: "Nome do Condutor",
                name: "driverName",
                type: "input",
                required: true,
                customRules: validators.FullNameRules
              },
              { label: "Telefone do Condutor", name: "driverPhone", type: "input", required: true, mask: masks.phone },
              { label: "Placa do Veículo KINTO", name: "licensePlate", type: "input", required: true, mask: masks.licensePlate },
              { label: "Nome da Empresa (PJ)", name: "company", type: "input", required: false },
              { label: "Modelo do Veículo", name: "model", type: "input", required: true },
              { label: "Ano Fab/Mod", name: "carYear", type: "input", required: true, mask: masks.yearyear, customRules: validators.AnoModeloRules },
              { label: "Veículo Blindado?", name: "armored", type: "switch", required: true, colon: false },
              { label: "Sensor de chuva?", name: "rainSensor", type: "switch", required: true, colon: false },
              { label: "Possui faixa degrade?", name: "degrade", type: "switch", required: true, colon: false }
            ]}
          />
        </FormView>)
      },
      onNext: (formData) => {
        store.dispatch(setVehicle(formData))
      }
    })
  }
}

export default VehicleStep
