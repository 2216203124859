import "./Send.css"

import React, { useEffect, useState } from "react"
import { connect, useStore } from "react-redux"
import { useNavigate } from "react-router-dom"
import { bindActionCreators, type Dispatch } from "redux"

import LogoKinto from "../../assets/kinto-symbol.svg"
import loaded from "../../assets/loaded.gif"
import loader from "../../assets/loader.gif"
import LogoKintoBrasil from "../../assets/LogoKintoBrasil.svg"
import { formActions, sendForm } from "../../store/ducks/form"
import { type Form } from "../../store/ducks/form/types"
import { type ApplicationState } from "../../store/store"

interface StateProps {
  form: Form
}

interface DispatchProps {
  sendForm: typeof sendForm
}

type OwnProps = StateProps & DispatchProps

const Send = (props: Partial<OwnProps>): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(true)
  const store = useStore()
  const navigate = useNavigate()

  const interval = setInterval(() => {
    if (!loading) {
      if (props.form?.sending.success) {
        clearInterval(interval)
        const loader = document.getElementById("send-loader")
        const loaded = document.getElementById("send-loaded")

        loader?.classList.add("hidden")
        loaded?.classList.remove("hidden")

        setTimeout(() => {
          const container = document.getElementById("send-loader-container")
          container?.classList.add("opacity-zero")
        }, 1000)
      }

      if (props.form?.sending.error) {
        navigate("/error")
      }
    }
  }, 3000)

  useEffect(() => {
    const state = store.getState() as { form: { data: Form } }
    if (state.form.data.finished) {
      setLoading(true)
      store.dispatch(sendForm())
    } else {
      navigate("/")
    }
  }, [])

  useEffect(() => {
    setLoading(props.form?.sending.loading ?? false)
  }, [props.form?.sending.loading])

  return (<div className="send-workspace">
    <div>
      <div id="send-loader-container">
        <img id="send-loader" src={loader} />
        <img id="send-loaded" className="hidden" src={loaded} />
      </div>
      <div className="send-header"><img src={LogoKintoBrasil} /></div>

      <div className="send-content">
        <div className="send-content-text">
          <p><h2>CHECKLIST DE SOLICITAÇÃO ENVIADO COM SUCESSO!</h2></p>
          <p>Prezado Cliente,</p>
          <p>O Checklist preenchido para a solicitação de abertura de sinistros para <b>vidros</b> foi enviado com sucesso para nossa equipe!</p>
          <p>Em breve, você receberá, por e-mail, a confirmação de envio do checklist de solicitação para abertura de sinistro e as demais orientações adicionais.</p>
          <p>Em caso de dúvidas, entre em contato com a nossa Central de Atendimento através do número 0800-703-0206, disponível de Segunda à Sexta-Feira das 8h às 18h, ou pelo e-mail: suporte@kintomobility.com.br e informe o número do protocolo do seu atendimento.</p>
          <p>Agradecemos a sua confiança em nossos serviços e ressaltamos que estamos dedicados a tornar o processo cada vez mais eficiente e ágil.</p>
        </div>
        <div className="send-content-image">
          <span>
            <p>Atenciosamente,</p>
            <p>KINTO BRASIL</p>
          </span>
          <img src={LogoKinto} />
        </div>
      </div>
    </div>
  </div>)
}

function mapStateToProps (state: ApplicationState): StateProps {
  return ({
    form: state.form.data
  })
}

function mapDispatchToProps (dispatch: Dispatch) {
  return bindActionCreators(formActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Send)
