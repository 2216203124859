import { type FormInstance } from "antd"
import { type JSXElementConstructor, type ReactElement } from "react"

import { type IStep } from "../types/IStep"

class Step<T = unknown> implements IStep<T> {
  current: (props: { formRef: FormInstance<any> }) => ReactElement<any, string | JSXElementConstructor<any>>
  redirect?: string | undefined
  next?: Step<any> | undefined
  nextText?: string | undefined
  onNext?: ((formData: T) => void) | undefined
  previous?: Step<any> | undefined
  previousText?: string | undefined
  redirectPrevious?: string | undefined
  nextRecaptcha?: boolean

  constructor (props: IStep<T>) {
    this.current = props.current
    this.redirect = props.redirect
    this.next = props.next
    this.nextText = props.nextText
    this.onNext = props.onNext
    this.previous = props.previous
    this.redirectPrevious = props.redirectPrevious
    this.previousText = props.previousText
    this.nextRecaptcha = props.nextRecaptcha
  }
}

export default Step
