import { FormFactory, masks, validators } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type FormInstance } from "antd"
import React from "react"

import { setWitness } from "../store/ducks/form"
import { type WitnessForm } from "../store/ducks/form/types"
import store from "../store/store"
import FormView from "../views/FormView"
import Step from "./Step"

class VehicleStep extends Step<WitnessForm> {
  constructor () {
    super({
      current: (props: { formRef: FormInstance }) => {
        return (<FormView title="Dados do Declarante" step={5}>
          <FormFactory
            formRef={props.formRef}
            formConfig={{
              labelAlign: "left",
              labelWrap: true,
              wrapperCol: { span: 22 },
              layout: "vertical"
            }}
            fields={[
              { label: "Nome Completo", name: "name", type: "input", customRules: validators.FullNameRules, required: true },
              { label: "Telefone", name: "phone", type: "input", mask: masks.phone, required: true },
              { label: "E-mail", name: "email", type: "input", customRules: validators.EmailRules, required: true },
              { label: "Assinatura", name: "signature", type: "signature", required: true }
            ]}
          />
        </FormView>)
      },
      onNext: (formData) => {
        store.dispatch(setWitness(formData))
      }
    })
  }
}

export default VehicleStep
