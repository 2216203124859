import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { type AccidentDetailsForm, type DocumentsForm, type FormState, type LocationForm, type VehicleForm, type WitnessForm } from "./types"

const initialState: FormState = {
  data: {
    finished: false,
    accidentDetails: {
      accidentDate: dayjs(),
      damagedSpots: [],
      damageIsBiggerThanACoin: false,
      details: "",
      otherDamages: "",
      glassRemainsAtCar: false
    },
    documents: {
      cnhImages: [],
      damageImages: [],
      plateImages: []
    },
    vehicle: {
      protocol: "",
      armored: false,
      carYear: "",
      company: "",
      degrade: false,
      driverName: "",
      driverPhone: "",
      licensePlate: "",
      model: "",
      rainSensor: false
    },
    location: {
      cep: "",
      city: "",
      state: ""
    },
    witness: {
      email: "",
      name: "",
      phone: "",
      signature: {
        dots: [],
        image: ""
      }
    },
    sending: {
      success: false,
      error: false,
      loading: false
    }
  }
}

const formSlice = createSlice({
  name: "FORM",
  initialState,
  reducers: {
    SET_DOCUMENTS: (state, action: { payload: DocumentsForm, type: string }) => {
      state.data.documents = action.payload
    },
    SET_VEHICLE: (state, action: { payload: VehicleForm, type: string }) => {
      state.data.vehicle = action.payload
    },
    SET_LOCATION: (state, action: { payload: LocationForm, type: string }) => {
      state.data.location = { ...state.data.location, ...action.payload }
    },
    SET_ACCIDENT_DETAILS_DATA: (state, action: { payload: Omit<AccidentDetailsForm, "damagedSpots" | "details">, type: string }) => {
      state.data.accidentDetails = { ...state.data.accidentDetails, ...action.payload }
    },
    SET_ACCIDENT_DETAILS_SPOTS: (state, action: { payload: Pick<AccidentDetailsForm, "damagedSpots">, type: string }) => {
      state.data.accidentDetails = { ...state.data.accidentDetails, ...action.payload }
    },
    SET_ACCIDENT_DETAILS_DETAILS: (state, action: { payload: Pick<AccidentDetailsForm, "details">, type: string }) => {
      state.data.accidentDetails = { ...state.data.accidentDetails, ...action.payload }
    },
    SET_WITNESS: (state, action: { payload: WitnessForm, type: string }) => {
      state.data.witness = action.payload
      state.data.finished = true
    },
    SEND_FORM: (state) => {
      state.data.sending = { error: false, success: false, loading: true }
    },
    SEND_FORM_SUCCESS: (state) => {
      state.data.sending = { error: false, success: true, loading: false }
    },
    SEND_FORM_ERROR: (state) => {
      state.data.sending = { error: true, success: false, loading: false }
    }
  }
})

export const {
  SET_DOCUMENTS: setDocuments,
  SET_VEHICLE: setVehicle,
  SET_ACCIDENT_DETAILS_DETAILS: setAccidentDetailsDetails,
  SET_ACCIDENT_DETAILS_SPOTS: setAccidentDetailsSpots,
  SET_ACCIDENT_DETAILS_DATA: setAccidentDetailsData,
  SET_LOCATION: setLocation,
  SET_WITNESS: setWitness,
  SEND_FORM: sendForm,
  SEND_FORM_ERROR: sendFormError,
  SEND_FORM_SUCCESS: sendFormSuccess
} = formSlice.actions

export const formActions = formSlice.actions

export default formSlice.reducer
