import { all, takeLatest } from "redux-saga/effects"

import { sendForm } from "./form"
import { sendFormSaga } from "./form/sagas"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function * rootSaga (): Generator {
  return yield all([
    takeLatest(sendForm, sendFormSaga)
  ])
}
