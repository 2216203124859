import "./Welcome.css"

import React from "react"

import KintoSymbol from "../../assets/kinto-symbol.svg"

// type OwnProps = { }

const Welcome = (): React.ReactElement => {
  return (<div className="view-welcome">
    <img className="welcome-logo" src={KintoSymbol} />
    <span className="welcome-description">Checklist - Solicitação para abertura de Sinistro para <b>Vidros</b></span>
    <div className="welcome-text">Neste link, você preencherá as informações necessárias para abertura de sinistro do seu veículo. Vamos te guiar neste processo para que seja fácil e eficiente.</div>
  </div>)
}

export default Welcome
