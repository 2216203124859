import { FormFactory } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type FormInstance } from "antd"
import React from "react"

import { setDocuments } from "../store/ducks/form"
import { type DocumentsForm } from "../store/ducks/form/types"
import store from "../store/store"
import FormView from "../views/FormView"
import Step from "./Step"

class DocumentsStep extends Step<DocumentsForm> {
  constructor () {
    super({
      current: (props: { formRef: FormInstance }) => {
        return (<FormView title="Documentos" step={1}>
          <FormFactory
            formRef={props.formRef}
            formConfig={{
              // labelCol: { span: 4 },
              wrapperCol: { span: 20 },
              layout: "vertical"
            }}
            fields={[
              {
                name: "cnhImages",
                label: "CNH Frente e verso",
                type: "document",
                size: 2,
                required: true,
                errorMessage: "Adicione imagens frente e verso da CNH"
              },

              {
                name: "plateImages",
                label: "Frente do Veículo (Incluir Placa)",
                type: "document",
                size: 1,
                required: true,
                errorMessage: "Adicione a imagem da placa do veículo"
              },

              {
                name: "damageImages",
                label: "Vidros Danificados",
                type: "document",
                size: 8,
                optional: 5,
                required: true,
                errorMessage: "Adicione pelo menos 3 imagens"
              }
            ]}
          />
        </FormView>)
      },
      onNext: (formData) => {
        store.dispatch(setDocuments(formData))
      }
    })
  }
}

export default DocumentsStep
