import React from "react"
import { createBrowserRouter, type RouteObject } from "react-router-dom"

import StepList from "./Steps/StepList"
import Send from "./views/Send"
import Workspace from "./views/Workspace"

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Workspace initialStep={ StepList.welcomeStep }/>
  },
  {
    path: "/send",
    element: <Send />
  },
  {
    path: "/error",
    element: <div>Ocorreu um erro, preencha o formulário novamente ou entre em contato.</div>
  }
]

function router () {
  return createBrowserRouter(routes)
}

export default router
