import "./ReviewTable.css"

import React from "react"

type OwnProps = {
  title: string
  items: Array<{
    name: string
    data?: string
    bold?: boolean
  }>
  className?: string
}

const ReviewTable = (props: OwnProps): React.ReactElement => {
  return (<div className={`review-table ${props.className ?? ""}`}>
      <div>{props.title}</div>
      <table>
        <tbody>
          {props.items.map(item => (<tr key={item.name} className="review-table-item">
            <td style={{ fontWeight: item.bold ? "bold" : "normal" }}>{item.name}</td>
            {item.data && <td>{item.data}</td>}
          </tr>))}
        </tbody>
      </table>
    </div>)
}

export default ReviewTable
