import { FormFactory } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type FormInstance } from "antd"
import React from "react"

import { setAccidentDetailsData } from "../store/ducks/form"
import { type AccidentDetailsForm } from "../store/ducks/form/types"
import store from "../store/store"
import FormView from "../views/FormView"
import Step from "./Step"

class AccidentStep extends Step<Omit<AccidentDetailsForm, "damagedSpots" | "details">> {
  constructor () {
    super({
      current: (props: { formRef: FormInstance }) => {
        return (<FormView title="Detalhes do Acidente" step={3}>
          <FormFactory
            formRef={props.formRef}
            formConfig={{
              labelAlign: "left",
              labelWrap: true,
              labelCol: { span: 6 },
              wrapperCol: { span: 16 },
              layout: "horizontal"
            }}
            fields={[
              { label: "Data do ocorrido", name: "accidentDate", type: "date", required: true },
              { label: "O vidro permanece no veículo?", name: "glassRemainsAtCar", type: "switch", required: true, colon: false },
              { label: "O dano é superior ao tamanho de uma moeda?", name: "damageIsBiggerThanACoin", type: "switch", required: true, colon: false }
            ]}
          />
        </FormView>)
      },
      onNext: (formData) => {
        store.dispatch(setAccidentDetailsData(formData))
      }
    })
  }
}

export default AccidentStep
