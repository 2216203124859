import { promisify } from "@kintobrasil/kinto-brasil-checklist-sinistro-components"
import { type UploadFile } from "antd"
import { v4 as uuid } from "uuid"

import { StreamToJson } from "../helpers/format"
import {
  type AccidentDetailsForm,
  type DocumentsForm,
  type LocationForm,
  type VehicleForm,
  type WitnessForm
} from "../store/ducks/form/types"

export interface SendFormArgs {
  recaptchaToken: string
  save?: boolean

  documents: DocumentsForm
  vehicle: VehicleForm
  accidentDetails: AccidentDetailsForm
  location: LocationForm
  witness: WitnessForm
}

async function uploadImage (
  imageData: string,
  imageName: string,
  recaptchaToken?: string
): Promise<string> {
  // @ts-expect-error
  const response = await AwsWafIntegration.fetch(
    `${process.env.REACT_APP_API_URI}/images`,
    {
      method: "PUT",
      headers: { Recaptcha: recaptchaToken },
      body: JSON.stringify({
        key: imageName,
        data: imageData
      })
    }
  )

  const signedURI = (await StreamToJson(response.body as ReadableStream)).signedURI

  return signedURI
}

async function UploadAllImages (
  images: UploadFile[],
  recaptchaToken: string,
  prefix?: string,
  sufix?: string
) {
  const urlList: string[] = []
  for (const image of images) {
    const url = await uploadImage(
      await promisify.blobTo64(image.originFileObj as Blob),
      `${prefix ?? ""}-${sufix ?? ""}-${uuid()}`,
      recaptchaToken
    )

    urlList.push(url)
  }

  return urlList
}

async function sendForm (args: SendFormArgs) {
  const formId = uuid()

  const cnhImagesURL: string[] = await UploadAllImages(
    args.documents.cnhImages,
    args.recaptchaToken,
    args.vehicle.licensePlate,
    `${formId}-cnh`
  )
  const damageImagesURL: string[] = await UploadAllImages(
    args.documents.damageImages,
    args.recaptchaToken,
    args.vehicle.licensePlate,
    `${formId}-damages`
  )
  const plateImagesURL: string[] = await UploadAllImages(
    args.documents.plateImages,
    args.recaptchaToken,
    args.vehicle.licensePlate,
    `${formId}-plate`
  )
  const signatureURL: string = await uploadImage(
    args.witness.signature.image,
    `${args.vehicle.licensePlate}-${formId}-signature-${uuid()}`,
    args.recaptchaToken
  )

  const body = {
    formId,
    type: "sinistro_vidros",
    accidentDetails: args.accidentDetails,
    location: args.location,
    recaptchaToken: args.recaptchaToken,
    vehicle: args.vehicle,
    witness: {
      ...args.witness,
      signature: signatureURL
    },
    documents: {
      cnhImages: cnhImagesURL,
      damageImages: damageImagesURL,
      plateImages: plateImagesURL
    }
  }

  const headers = {
    Recaptcha: args.recaptchaToken,
    application: "sinistro_vidros",
    "Content-Type": "application/json"
  }

  // @ts-expect-error
  const response = await AwsWafIntegration.fetch(
    `${process.env.REACT_APP_API_URI}/form`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    }
  )

  return response
}

export default {
  uploadImage,
  sendForm
}
