import React from "react"

import Welcome from "../views/Welcome"
import Step from "./Step"

class WelcomeStep extends Step {
  constructor () {
    super({
      current: () => <Welcome />
    })
  }
}

export default WelcomeStep
