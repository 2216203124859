import "./Review.css"

import React from "react"

import ReviewTable from "../../components/ReviewTable"
import { toReadableBoolean, toReadableSpot } from "../../helpers/format"
import { type Form } from "../../store/ducks/form/types"

type OwnProps = {
  data: Form
}

const Review = (props: OwnProps): React.ReactElement => {
  const {
    accidentDetails,
    documents,
    location,
    vehicle
  } = props.data

  const formatedSpots = accidentDetails.damagedSpots?.reduce<{ centrais: string[], dianteiro: string[], traseiro: string[], outros: string }>((prev, curr) => {
    switch (curr) {
      case "central-parabrisa":
      case "central-traseiro":
        prev.centrais.push(toReadableSpot(curr))
        break

      case "dianteiro-esquerdo":
      case "dianteiro-direito":
        prev.dianteiro.push(toReadableSpot(curr))
        break
      case "traseiro-esquerdo":
      case "traseiro-direito":
        prev.traseiro.push(toReadableSpot(curr))
        break
    }

    return prev
  }, {
    centrais: [],
    dianteiro: [],
    traseiro: [],
    outros: ""
  }) ?? {}

  if (accidentDetails.otherDamages) { formatedSpots.outros = accidentDetails.otherDamages }

  return (<div className="review-container">
    <ReviewTable className="review-veiculo" title="Veículo" items={[
      { name: "Condutor", data: vehicle.driverName },
      { name: "Telefone", data: vehicle.driverPhone },
      { name: "Placa", data: vehicle.licensePlate },
      { name: "Empresa", data: vehicle.company ?? "N/A" },
      { name: "Modelo", data: vehicle.model },
      { name: "Ano Mod/Fab", data: vehicle.carYear },
      { name: "Protocolo", data: vehicle.protocol },
      { name: "Veículo Blindado?", data: toReadableBoolean(vehicle.armored) },
      { name: "Sensor de Chuva?", data: toReadableBoolean(vehicle.rainSensor) },
      { name: "Possui faixa degradê?", data: toReadableBoolean(vehicle.degrade) }
    ]}/>

    <ReviewTable className="review-ocorrencia" title="Dados da Ocorrência" items={[
      { name: "Data do Ocorrido", data: accidentDetails.accidentDate?.format("DD/MM/YYYY") },
      { name: "O vidro permanece no veículo?", data: toReadableBoolean(accidentDetails.glassRemainsAtCar) },
      { name: "O dano é superior ao tamanho de uma moeda?", data: toReadableBoolean(accidentDetails.damageIsBiggerThanACoin) }
    ]}/>

    <ReviewTable className="review-atendimento" title="Local de Atendimento" items={[
      { name: "CEP", data: location.cep },
      { name: "Cidade", data: location.city },
      { name: "UF", data: location.state }
    ]}/>

    <ReviewTable className="review-danos" title="Vidros danificados" items={[
      { name: "Centrais:", bold: true },
      ...(formatedSpots.centrais.length ? formatedSpots.centrais.map(value => ({ name: value })) : [{ name: "N/A" }]),
      { name: "Lateral Dianteiro:", bold: true },
      ...(formatedSpots.dianteiro.length ? formatedSpots.dianteiro.map(value => ({ name: value })) : [{ name: "N/A" }]),
      { name: "Lateral Traseiro:", bold: true },
      ...(formatedSpots.traseiro.length ? formatedSpots.traseiro.map(value => ({ name: value })) : [{ name: "N/A" }]),
      { name: "Outros", bold: true },
      { name: formatedSpots.outros ? formatedSpots.outros : "N/A" }
    ]}/>

    <ReviewTable className="review-imagens" title="Imagens" items={[
      { name: "CNH", data: `${documents.cnhImages?.length ?? 0} Imagens` },
      { name: "Placa", data: `${documents.plateImages?.length ?? 0} Imagens` },
      { name: "Vidros Danificados", data: `${documents.damageImages?.length ?? 0} Imagens` }
    ]}/>
  </div>)
}

export default Review
