import { configureStore, type Store } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"

import { type Form } from "./ducks/form/types"
import rootReducer from "./ducks/rootReducer"
import rootSaga from "./ducks/rootSaga"

export interface ApplicationState {
  form: { data: Form }
}

const sagaMiddleware = createSagaMiddleware()

const store: Store<ApplicationState> = configureStore({
  reducer: rootReducer,
  middleware: (gDM) => gDM().concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)

export default store
